import { RouteConfig } from "vue-router";
import { viewModule } from "../utils";

const paths: RouteConfig[] = [
  {
    path: '/task-tracker/',
    name: 'task_tracker',
    component: viewModule('TaskTracker'),
    meta: {
      section: 'task_tracker'
    },
  },
  {
    path: '/desk/:_id',
    name: 'tasks_desk',
    component: viewModule('TaskTracker', 'TasksDesk'),
    meta: {
      section: 'tasks_desk'
    },
  },
  
]

export default paths