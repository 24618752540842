import { RouteConfig } from "vue-router"
import { viewModule } from "../utils"

const paths: RouteConfig[] = [
  {
    path: '/courses',
    name: 'courses',
    component: viewModule('LMS'),
    meta: {
      section: 'courses'
    },
  },
  {
    path: '/courses/settings',
    name: 'course',
    component: viewModule('LMS','Settings'),
    meta: {
      section: 'courses'
    },
  },
  {
    path: '/courses/:id',
    name: 'course',
    component: viewModule('LMS','Course'),
    meta: {
      section: 'courses'
    },
  },
]
export default paths