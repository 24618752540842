import { RouteConfig } from "vue-router";
import { viewModule } from "../utils";

const paths: RouteConfig[] = [
  {
    path: '/adaptation',
    name: 'adaptation',
    component: viewModule('Adaptation'),
    meta: {
      section: 'adaptation'
    },
    children: [
      {
        // Страница пользовательской адаптации
        path: '/adaptation/user/:adapt_id',
        name: 'adaptation_user',
        component: viewModule('Adaptation', 'components/ProgressPage/TrackForUser'),
        meta: {
          section: 'adaptation'
        },
      }
    ]
  },
  {
    // Админская страница управления данными о треке
    path: '/adaptation/:_id',
    name: 'adaptation_track',
    component: viewModule('Adaptation', 'Track'),
    meta: {
      section: 'adaptation'
    },
  }
]

export default paths