import * as Actions from './actions.type'

import * as Mutations from './mutations.type'

import * as Service from './shop.service'
import { FullBalance } from './types'

interface State {
  settings: {
    _id: string,
    client_id: string,
    name: string,
    coin: string,
    coin_short: string,
    description: string,
    pic: string, // URL to pic on server
    managers: string[], // ID of the empl
  },
  achieves: any[],
  items: any[],
  statistic: any[],
  earns: any[], // начисления монет (или удержания - с минусом)
  balance: number,
  user_achievements: any[],
  purchases: any[], // покупки за монеты
  balances: FullBalance[], // балансы сотрудников
}

const store: State = {
  settings: {
    _id: '',
    client_id: '',
    name: 'Shop!',
    coin: '',
    coin_short: '',
    description: '',
    pic: '', // URL to pic on server
    managers: [], // ID of the empl
  },
  achieves: [],
  items: [],
  statistic: [],
  balance: 0,
  user_achievements: [],
  earns: [],
  purchases: [],
  balances: [],
}

const actions = {
  [Actions.GET_SHOP]: Service.getShop,
  [Actions.SAVE_SHOP]: Service.saveShop,
  [Actions.GET_ACHIEVMENTS]: Service.getAchievements,
  [Actions.GET_USER_ACHIEVMENTS]: Service.getUserAchievements,
  [Actions.SEND_ACHIEV]: Service.addAchievment,
  [Actions.EDIT_ACHIEV]: Service.editAchievment,
  [Actions.DEL_ACHIEV]: Service.delAchievment,
  [Actions.DEL_SHOP_ITEM]: Service.delShopItem,
  [Actions.GET_SHOP_ITEMS]: Service.getShopItems,
  [Actions.SEND_SHOP_ITEM]: Service.addShopItem,
  [Actions.EDIT_SHOP_ITEM]: Service.editShopItem,
  [Actions.ACCRUE_COINS]: Service.accrueCoins,
  [Actions.BUY_ITEM]: Service.buyItem,
  [Actions.GET_BALANCE]: Service.getBalance,
  [Actions.GET_STATS]: Service.getStatistics,
  [Actions.CANCEL_ACCRUAL]: Service.delAwardCoins,
  [Actions.ADD_ITEM_AMOUNT]: Service.addItemsAmount,
  [Actions.GET_ALL_BALANCES]: Service.getAllBalances,
  [Actions.NULLIFY_BALANCES]: Service.nullifyBalances,
}

const mutations = {
  [Mutations.SET_SHOP](state: State, settings: any) {
    state.settings = settings
  },
  [Mutations.SET_ACHIEVES](state: State, achieves: any) {
    state.achieves = achieves
  },
  [Mutations.ADD_ACHIEVE](state: State, achieve: any) {
    state.achieves = [...state.achieves, achieve]
  },
  [Mutations.UPD_ACHIEVE](state: State, achieve: any) {
    state.achieves = state.achieves.map(a => a._id === achieve._id ? achieve : a)
  },
  [Mutations.SET_SHOP_ITEMS](state: State, items: any) {
    state.items = items
  },
  [Mutations.ADD_SHOP_ITEM](state: State, item: any) {
    state.items = [...state.items, {...item, rest: item.amount}]
  },
  [Mutations.UPD_SHOP_ITEM](state: State, item: any) {
    state.items = state.items.map(i => i._id === item._id ? item : i)
  },
  [Mutations.SET_ITEM_REST](state: State, data: {item_id: string, rest: number}) {
    state.items = state.items.map(i => i._id === data.item_id ? {...i, rest: data.rest} : i)
  },
  [Mutations.REMOVE_ACHIVE](state: State, _id: any) {
    state.achieves = state.achieves.filter(a => a._id != _id);
  },
  [Mutations.REMOVE_SHOP_ITEM](state: State, _id: any) {
    state.items = state.items.filter(a => a._id != _id);
  },
  [Mutations.SET_BALANCE](state: State, balance: number) {
    state.balance = balance || 0;
  },
  [Mutations.SET_USER_ACHIEVES](state: State, ach: any[]) {
    state.user_achievements = ach;
  },
  [Mutations.SET_STATISTIC](state: State, stats: any) {
    state.statistic = stats;
  },
  [Mutations.DEL_STAT_ITEM](state: State, op_id: string) {
    state.statistic = state.statistic.filter(a => a._id != op_id);
  },
  [Mutations.SET_BALANCES](state: State, balances: FullBalance[]) {
    state.balances = balances
  }
}

const getters = {
  mapAchievements(state: State): Map<string, object> {
    const map = new Map()
    state.achieves.forEach(a => {
      map.set(a._id, a)
    })
    return map
  }
}

export default {
  // namespaced: true,
  state: store,
  getters,
  actions,
  mutations
};