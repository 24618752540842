import { RouteConfig } from 'vue-router';
import { viewModule } from '../utils'

const paths: RouteConfig[] = [
  {
    path: '/groups/',
    name: 'groups',
    component: viewModule('Groups'),
    meta: {
      section: 'groups'
    },
  },
  {
    path: '/groups/:_id',
    name: 'group',
    component: viewModule('Groups', 'Group'),
    meta: {
      section: 'groups'
    },
  },
  {
    path: '/group/:_id',
    name: 'group',
    component: viewModule('Groups', 'Group'),
    meta: {
      section: 'groups'
    },
  }
]

export default paths