import { RouteConfig } from "vue-router";
import { viewModule } from "../utils";

const paths: RouteConfig[] = [
  {
    path: '/chat',
    name: 'chats',
    component: viewModule('Chat'),
    meta: {
      section: 'chat'
    },
  },
  {
    path: '/chat/:_id',
    name: 'chat',
    component: viewModule('Chat'),
    meta: {
      section: 'chat'
    },
  },
  
]

export default paths