import Home from '@/modules/SinglePages/Home.vue';
import { viewModule } from './utils'

import basePaths from './base'
// import singlePages from './single_pages';
// import groups from './modules/groups'
// import articles from './modules/articles';
// import polls from './modules/polls';
// import adaptation from './modules/adaptation';
// import lms from './modules/LMS';
// import services from './modules/services';
// import chat from './modules/chat';
// import task_tracker from './modules/task_tracker';
import { RouteConfig } from 'vue-router';

// Функция для автоматической загрузки всех модулей роутинга
const loadRouteModules = (): RouteConfig[]  => {
  const modules: RouteConfig[] = [];
  console.log('Compile routes...');
  
  // Динамически импортируем все файлы .ts из папки modules
  const requireModule = require.context(
    './modules', // путь к папке
    false,       // не искать в подпапках
    /\.ts$/      // только файлы с расширением .ts
  );

  requireModule.keys().forEach((fileName) => {
    // Импортируем модуль
    const moduleConfig = requireModule(fileName);
    // Добавляем маршруты из модуля в массив (берем default экспорт)
    modules.push(...(moduleConfig.default || []));
  });

  return modules;
};

// Собираем все дочерние маршруты
const childrenRoutes = loadRouteModules();

export default [
  /*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ './views/About.vue'),
  },*/
  ...basePaths,
  {
    path: '/',
    name: '',
    component: Home,
    children: 
    [
      ...childrenRoutes,
      {
        path: '/404',
        name: 'NotFound',
        component: viewModule('SinglePages','404'),
      },
      {
        path: '/*',
        name: 'NotFound',
        component: viewModule('SinglePages','404'),
      },
    ],
  },

]
