export const CREATE_GROUP = 'CREATE_GROUP';
export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_BY_CLIENT = 'GET_GROUPS_BY_CLIENT';
export const GET_GROUPS_USER_CAN_READ = 'GET_GROUPS_USER_CAN_READ';

export const GET_CURRENT_GROUP = 'GET_CURRENT_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const EDIT_GROUP = 'EDIT_GROUP';

export const ADD_GRANTS_USER = 'ADD_GRANTS_USER';
export const DEL_GRANTS_USER = 'DEL_GRANTS_USER';

// сместить владельца групп (найдя по id его группы)
export const REPLACE_GROUPS_OWNER = 'REPLACE_GROUPS_OWNER'
// заменить владельца конкретной группы
export const CHANGE_GROUP_OWNER = 'CHANGE_GROUP_OWNER'

export const SET_ADMIN = 'SET_ADMIN'


