<template>
  <div 
    :class="`card col ${w100 ? 'w-100':''} ${rounded ? 'radius2': ''}`"
    :style="{width, minWidth, height, backgroundColor: bgColor}" 
    @click="$emit('click', $event)"
  >
    <div v-if="header" class="head" :class="[noPad ? 'p0' : 'p4', $mq == 'phone' ? 'col gap4' : 'flex-between']">
      <div class="header" :class="{'w-75': titleActionText && $mq != 'phone'}">
        <div 
          v-if="icon"
          class="header-icon mr4" 
          :class="icon_color.slice(0,4) != 'rgba' ? `bg-${icon_color}` : ''"
          :style="{backgroundColor: icon_color.slice(0,4) == 'rgba' ? icon_color : 'white'}"
        >
          <SvgIcon :icon-name="icon" width="24" height="24" class="white" />
        </div>
        <div class="title">{{ header }}</div>
      </div>
      <Button
        v-if="titleActionText"
        link
        icon="edit"
        class="action-button"
        @click="$emit('title-action')"
      >
        {{ titleActionText }}
      </Button>
    </div>
    <div
      :class="[
        noPad ? 'p0' : pad, 
        h100 ? 'h-100' : '', 
        gap ? `gap${gap}` : '' 
      ]"
      class="w-100 col f-grow-1 relative"
    >
      <slot />
    </div>
    <div v-if="$slots.footer" :class="noPad ? 'p0' : 'p4'">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    SvgIcon: () => import('@/components/common/svg-icon'),
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    icon_color: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: '',
    },
    titleActionText: {
      type: String,
      default: '',
    },
    // no padding in body
    noPad: {
      type: Boolean,
      default: false,
    },
    pad: {
      type: String,
      default: 'p4',
    },
    gap: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    minWidth: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: 'white',
    },
    h100: Boolean,
    w100: {
      type: Boolean,
      default: true,
    },
    rounded: Boolean
  },
  emits: ['title-action'],

}
</script>

<style scoped lang="scss">
.card {
  box-shadow: 0px 2px 7px rgba(26, 27, 41, 0.15);
    & > .head {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .title {
        font-weight: 500;
      }
      @media (min-width: 481px) {
        .btn.action-button {
          width: 25%;
          justify-content: end;
        }
      }
      
      .header {
        font-size: 20px;
        display: flex;
        .header-icon {
          width: 32px;
          height: 32px;
          border-radius: 4px;
          padding: 4px;
        }
        .title {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
}
</style>