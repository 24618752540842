import { view, viewModule } from './utils'

const paths = [{
    path: '/login/',
    name: 'Login',
    component: viewModule('SinglePages','Login'),
  },
  {
    path: '/login_new/',
    name: 'login_new',
    component: viewModule('SinglePages','LoginNew'),
  },
  {
    name: 'authorize',
    path: '/authorize',
    component: view('Authorize'),
  },
  {
    path: '/register/',
    name: 'register',
    component: view('Register'),
  },
  {
    path: '/forgot-password/',
    name: 'ForgotPassword',
    component: viewModule('SinglePages','Login'),
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: viewModule('SinglePages','Login'),
  }
]

export default paths