import { RouteConfig } from "vue-router";
import { viewModule } from "../utils";

const paths: RouteConfig[] = [
  {
    path: '/articles/',
    name: 'articles',
    component: viewModule('Articles'),
    meta: {
      section: 'articles'
    },
  },
  {
    path: '/articles/:parent',
    name: 'articlesByParent',
    component: viewModule('Articles'),
    meta: {
      section: 'articles'
    },
  },
  {
    path: '/article/:_id',
    name: 'article',
    component: viewModule('Articles', 'Article'),
    meta: {
      section: 'articles'
    },
  },
  {
    path: '/vacancies/',
    name: 'vacancies',
    component: viewModule('Articles','Vacancies'),
    meta: {
      section: 'vacancies'
    },
  },
  {
    path: '/vacancy/:_id',
    name: 'vacancy',
    component: viewModule('Articles','Article'),
    meta: {
      section: 'vacancies'
    },
  }
]

export default paths