import { RouteConfig } from "vue-router";
import { viewModule } from "../utils";

const paths: RouteConfig[] = [
  {
    path: '/polls',
    name: 'polls',
    component: viewModule('Polls'),
    meta: {
      section: 'polls'
    },
  },
  {
    path: '/poll/new',
    name: 'new_poll',
    component: viewModule('Polls','Poll'),
    props: { isNew: true },
    meta: {
      section: 'polls'
    },
  },
  {
    path: '/poll/:_id',
    name: 'poll',
    component: viewModule('Polls','Poll'),
    props: true,
    meta: {
      section: 'polls'
    },
  },
  {
    path: '/poll/:_id/results',
    name: 'poll_results',
    component: viewModule('Polls','PollResults'),
    props: true,
    meta: {
      section: 'polls'
    },
  },
  
]

export default paths