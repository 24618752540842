import { RouteConfig } from "vue-router"
import { viewModule } from "../utils"

const paths: RouteConfig[] = [
  {
    path: '/company',
    name: 'company',
    component: viewModule('Company'),
    meta: {
      section: 'company'
    },
  },
  {
    path: '/profile/:_id',
    name: 'profile',
    component: viewModule('UserProfile'),
    meta: {
      section: 'profile'
    },
  },
  {
    path: '/post/:_id',
    name: 'post',
    component: viewModule('Posts'),
    meta: {
      section: 'posts'
    },
  },
  {
    path: '/calendar/',
    name: 'calendar',
    component: viewModule('Calendar'),
    meta: {
      section: 'calendar'
    },
  },
  {
    path: '/addressBook/',
    name: 'addressBook',
    component: viewModule('AddressBook'),
    meta: {
      section: 'addressBook'
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: viewModule('Notifications'),
    meta: {
      section: 'notifications'
    },
  },
  {
    path: '/structure_new',
    name: 'structure_new',
    component: viewModule('Structures'),
    meta: {
      section: 'structure_new'
    },
  },
  {
    path: '/idea',
    name: 'idea',
    component: viewModule('Ideas'),
    meta: {
      section: 'idea'
    },
  },
  {
    path: '/report_violation',
    name: 'report_violation',
    component: viewModule('NoSilent'),
    meta: {
      section: 'report_violation'
    },
  },
  {
    path: '/employees/',
    name: 'employees',
    component: viewModule('CompanyManagement'),
    meta: {
      section: 'employees'
    },
  },
  {
    path: '/clients',
    name: 'clients',
    component: viewModule('Clients'),
    meta: {
      section: 'clients'
    },
  },
  {
    path: '/shop/',
    name: 'shop',
    component: viewModule('Shop'),
    meta: {
      section: 'shop'
    },
  },
  {
    path: '/admin/',
    name: 'admin',
    component: viewModule('AdminPanel'),
    meta: {
      section: 'admin'
    },
  },
  {
    path: '/about/',
    name: 'about',
    component: viewModule('SinglePages','About'),
    meta: {
      section: 'about'
    },
  },
  {
    path: '/birthdays/',
    name: 'birthdays',
    component: viewModule('SinglePages','Birthdays'),
    meta: {
      section: 'birthdays'
    },
  },
  {
    path: '/vacations',
    name: 'vacations',
    component: viewModule('Vacations'),
    meta: {
      section: 'vacations'
    },
  },
  {
    path: '/help/',
    name: 'help',
    component: viewModule('SinglePages','Help'),
    meta: {
      section: 'help'
    },
  },
  
]
export default paths