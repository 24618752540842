<template>
  <svg 
    :class="[iconClass, clickable ? 'pointer' : '']" 
    :width="width"
    :height="width"
    :style="{ color: color, flexShrink: 0 }"
    @click="$emit('click')"
  >
    <use :xlink:href="iconHref" />
  </svg>
</template>

<script>
export default {
  name: 'SvgUse',
  props: {
    icon: {
      type: String,
      required: true
    },
    width: {
      type: [String, Number],
      default: '16px'
    },
    clickable: {
      type: Boolean,
    },
    // height: {
    //   type: String,
    //   default: '24px'
    // },
    color: {
      type: String,
      default: '#5656E0' //'#3380d8'
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconHref() {
      return `/all-icons.svg#${this.icon}`;
    }
  }
};
</script>

<style scoped>
/* svg {
  fill: currentColor;
  stroke: currentColor;
} */
</style>
