import { Course, CourseSettings } from "./types";

import * as Actions from './actions.type'
import * as Mutations from './mutations.type'
import * as Services from './services'

interface State {
  list: Course[], // список курсов для начальной страницы
  item: Course | null,  // открытый курс при редактировании в диалоге
  activeCourse: Course | null, // страница открытого курса
  courseSettings: CourseSettings | null,
  
}

const store: State = {
  list: [],
  item: null,
  activeCourse: null,
  courseSettings: null,
}

const actions = {
  [Actions.GET_ADMINS]: Services.getAdmins,
  [Actions.SAVE_ADMINS]: Services.setAdmins,
}
const mutations = {
  [Mutations.SET_ADMINS](state: State, admins: string[]) {
    state.courseSettings = {admins}
  }
}


export default {
  namespaced: true,
  state: store,
  // getters,
  actions,
  mutations,
};