import Vue from 'vue';
import { AxiosError, AxiosResponse } from 'axios';

import * as Mutations from './mutations.type'

// Добавление задачи в панель доски
export const getAdmins = async ({commit}: any, client_id: string) => { 
  const response:AxiosResponse = await Vue.axios.get(`api/clients/${client_id}/settings/admins/lms`)
  // изменение текущего трека
  commit(Mutations.SET_ADMINS, response.data || [])
}

// Добавление задачи в панель доски
export const setAdmins = async ({commit}: any, data: {client_id: string, admins: string[]}) => { 
  const response:AxiosResponse = await Vue.axios.put(`api/clients/${data.client_id}/settings/admins/lms`, data.admins)
  // изменение текущего трека
  commit(Mutations.SET_ADMINS, response.data || [])
}