import { RouteConfig } from "vue-router"
import { viewModule } from "../utils"

const paths: RouteConfig[] = [
  {
    path: '/services/',
    name: 'services',
    component: viewModule('Services'),
    meta: {
      section: 'services'
    },
  },
  {
    path: '/service/:_id',
    name: 'service',
    component: viewModule('Services','Service'),
    meta: {
      section: 'services'
    },
  },
]
export default paths